'use client'

import InitialSigninPopUp from './InitialSigninPopUp'

export default function PopUps({ children }: { children: React.ReactNode }) {
  return (
    <>
      <InitialSigninPopUp />
      {children}
    </>
  )
}
