import Image, { ImageProps } from 'next/image'
import React from 'react'

const ImageWithArweaveFallback = (props: ImageProps) => {
  const { src, ...rest } = props

  return (
    <Image
      {...rest}
      src={src}
      onError={(event) => {
        event.currentTarget.id = `${src}?fallback=true`
        event.currentTarget.src = `${src}?fallback=true`
        event.currentTarget.srcset = `${src}?fallback=true`
      }}
    />
  )
}

export default ImageWithArweaveFallback
