import(/* webpackMode: "eager" */ "/vercel/path0/components/AudioPlayer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/FacebookPixel.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Footer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Navigation.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/NotificationsModal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Popups.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Toasts.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/tokens/ForceScroll.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/accessible-icon.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/alert-dialog.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/aspect-ratio.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/avatar.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/callout.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/checkbox.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/context-menu.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/dialog.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/dropdown-menu.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/hover-card.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/popover.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/portal.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/radio-group.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/scroll-area.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/select.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/separator.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/slider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/switch.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/tabs.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/text-field.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/tooltip.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/visually-hidden.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/theme-panel.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/theme.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/(main)/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../styles/fonts/NeueHaasGrotText-55Roman-Web.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"../../styles/fonts/NeueHaasGrotText-56Italic-Web.woff2\",\"style\":\"italic\",\"weight\":\"400\"},{\"path\":\"../../styles/fonts/NeueHaasGrotText-75Bold-Web.woff2\",\"style\":\"normal\",\"weight\":\"700\"},{\"path\":\"../../styles/fonts/NeueHaasGrotText-76BoldItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"700\"}]}],\"variableName\":\"neueHaas\"}")