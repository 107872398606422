'use client'

import clsx from 'clsx'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

export default function Footer() {
  const pathname = usePathname()
  let hideFooter = false

  if (pathname.includes('/feed')) {
    hideFooter = true
  } else if (pathname.includes('/search')) {
    hideFooter = true
  } else if (pathname.includes('/notifications')) {
    hideFooter = true
  }

  const links = [
    {
      name: 'About',
      url: '/about',
    },
    {
      name: 'FAQ',
      url: 'https://help.ninaprotocol.com',
    },
    {
      name: 'Developers',
      url: 'https://dev.ninaprotocol.com',
    },
    {
      name: 'Terms',
      url: 'https://nina-protocol.notion.site/Nina-Protocol-Terms-of-Service-45db765d2ccf4000946af0af2ee491b9',
    },
  ]

  const socials = [
    { name: 'Twitter', url: 'https://twitter.com/ninaprotocol' },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/ninaprotocol/',
    },
    {
      name: 'Discord',
      url: 'https://discord.gg/ePkqJqSBgj',
    },
    {
      name: 'Soundcloud',
      url: 'https://soundcloud.com/ninaprotocol',
    },
  ]

  return (
    <div
      className={clsx(
        'mb-[64px] mt-[192px] flex flex-col items-start  pb-12 md:mt-[240px]  md:flex-row md:items-end md:justify-between md:pb-28',
        hideFooter && 'hidden',
      )}
    >
      <div className="flex flex-col gap-y-8 text-left md:flex-row md:gap-x-12 md:gap-y-0">
        {links.map((link, index) => (
          <Link href={link.url} key={index}>
            <p className="caption-1 md:hover:opacity-70" key={index}>
              {link.name}
            </p>
          </Link>
        ))}
      </div>
      <div className="mt-28 md:mt-0 md:inline-block md:text-center"></div>
      <div className="flex flex-col gap-y-8 text-left md:flex-row md:gap-x-12 md:gap-y-0 md:text-right">
        {socials.map((link, index) => (
          <Link
            href={link.url}
            key={index}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="caption-1 md:hover:opacity-70" key={index}>
              {link.name}
            </p>
          </Link>
        ))}
      </div>
      <p className="caption-1 mt-28 block md:mt-0 md:hidden">{`© ${new Date().getFullYear()} Nina Protocol`}</p>
    </div>
  )
}
