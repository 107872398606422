'use client'

import { useRouter } from 'next/navigation'
import { useContext, useEffect, useState } from 'react'
import Modal from '@/components/Modal'
import Wallet from '@/contexts/WalletContext'
import {
  ButtonColor,
  ButtonFont,
  ButtonResponsiveness,
  ButtonVariant,
  ModalType,
} from '@/lib/types'
import { allowArticleCreate } from '@/lib/utils/allowArticleCreate'
import HubCreate from './HubCreate'
import Login from './Login'
import ReleaseCreate from './ReleaseCreate'
import Button from './tokens/Button'

type CreateEntryModalProps = {
  forceOpen?: boolean
  setForceOpen?: (forceOpen: boolean) => void
  hideTrigger?: boolean
  modalLabelVariant?: ButtonVariant
  modalLabel?: string
}

export default function CreateEntryModal({
  forceOpen,
  setForceOpen,
  hideTrigger = false,
  modalLabelVariant,
  modalLabel,
}: CreateEntryModalProps) {
  const [isOpen, setIsOpen] = useState(forceOpen || false)
  const [releaseCreateIsOpen, setReleaseCreateIsOpen] = useState(false)
  const [hubCreateIsOpen, setHubCreateIsOpen] = useState(false)
  const router = useRouter()
  const { wallet, sessionSignature } = useContext(Wallet.Context)
  useEffect(() => {
    if (forceOpen) {
      setIsOpen(true)
    }
  }, [forceOpen])

  useEffect(() => {
    if (!isOpen && setForceOpen) {
      setForceOpen(false)
    }
  }, [isOpen])

  const openFlow = (type: string) => {
    if (type === 'release') {
      setReleaseCreateIsOpen(true)
    } else if (type === 'hub') {
      setHubCreateIsOpen(true)
    }

    if (setForceOpen) {
      setForceOpen(false)
    }

    setIsOpen(false)
  }

  const handleClose = () => {
    setIsOpen(false)

    if (setForceOpen) {
      setForceOpen(false)
    }
  }

  return (
    <div>
      <Modal
        label={modalLabel || 'Create'}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalType={ModalType.Default}
        modalLabelVariant={modalLabelVariant || ButtonVariant.Unstyled}
        modalHeader={
          !wallet.publicKey && !sessionSignature ? 'Sign In' : 'Create'
        }
        disableKeydownSubmit={true}
        closeCallback={handleClose}
        hideTrigger={hideTrigger}
      >
        <div className="flex flex-col">
          {!wallet.publicKey && (
            <Login
              loginPromptString="Create an account or sign in to publish a release or create a hub."
              variant={'loginPrompt'}
            />
          )}
          {wallet.publicKey && (
            <div>
              <div className="mb-28 flex flex-col">
                <div className="w-full bg-blue-5 p-20">
                  <p className="body-1 mb-[28px] mt-12 text-blue-82">
                    Upload a single / EP / album now.
                  </p>
                  <Button
                    onClick={() => openFlow('release')}
                    color={ButtonColor.Solid}
                    textColor={ButtonColor.White}
                    variant={ButtonVariant.Large}
                    label={'Create Release'}
                    responsiveness={ButtonResponsiveness.Full}
                    font={ButtonFont.Body1}
                  />
                </div>
              </div>
              <div className="flex flex-col ">
                <div className="w-full bg-blue-5 p-20">
                  <p className="body-1 mb-28 mt-12 text-blue-82">
                    Hubs are channels for artist / label pages, playlists and
                    more.
                  </p>
                  <Button
                    onClick={() => openFlow('hub')}
                    color={ButtonColor.Solid}
                    textColor={ButtonColor.White}
                    variant={ButtonVariant.Large}
                    label={'Create Hub'}
                    responsiveness={ButtonResponsiveness.Full}
                    font={ButtonFont.Body1}
                  />
                </div>
              </div>
              {allowArticleCreate(wallet) && (
                <div className="mt-8 flex flex-col">
                  <Button
                    onClick={() => {
                      setIsOpen(false)
                      router.push('/articles/create')
                    }}
                    color={ButtonColor.Grey}
                    textColor={ButtonColor.Grey}
                    variant={ButtonVariant.Large}
                    label={'Article'}
                    font={ButtonFont.Body1}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>

      <ReleaseCreate
        isOpen={releaseCreateIsOpen}
        setIsOpen={setReleaseCreateIsOpen}
      />
      <HubCreate isOpen={hubCreateIsOpen} setIsOpen={setHubCreateIsOpen} />
    </div>
  )
}
