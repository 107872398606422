'use client'

import clsx from 'clsx'
import { useContext, useMemo } from 'react'
import Audio from '@/contexts/AudioContext'
import { TrackListType } from '@/lib/types'
import QueueListItem from './QueueListItem'

type QueueListProps = {
  type: TrackListType
}

export default function QueueList(props: QueueListProps) {
  const { type } = props
  const { queue, history, activeIndex } = useContext(Audio.Context)

  const displayList = useMemo(() => {
    switch (type) {
      case TrackListType.Queue:
        return queue
      case TrackListType.History:
        return history
      default:
        return queue
    }
  }, [queue, queue.length, activeIndex, type])

  return (
    <div
      className={clsx(
        'no-scrollbar overflow-x-hidden bg-blue-82 pb-[120px] pr-0  xs:max-h-[100vh] xs:overflow-y-scroll  sm:max-h-none sm:min-h-[50vh] sm:pb-[80px] md:max-h-[596px] md:overflow-x-hidden md:overflow-y-scroll',
        displayList.length > 6 ? 'xs:pb-[326px] lg:pb-[186px]' : 'lg:pb-0',
        // displayList.length > 3 ? ' md:pb-[430px]' : 'md:pb-0',
      )}
    >
      <div>
        {displayList.map((item, index) => {
          if (type === TrackListType.Queue && index <= activeIndex) {
            return null
          }

          return (
            <div key={index}>
              <QueueListItem
                type={type}
                queueItem={item}
                index={index}
                parentLength={displayList?.length}
              />
            </div>
          )
        })}
      </div>

      {type === TrackListType.Queue && displayList.length === 0 && (
        <p className="body-1 text-blue-35">There are no tracks in the queue.</p>
      )}
      {type === TrackListType.History && displayList.length === 0 && (
        <p className="body-1 text-blue-35">There are no tracks in history.</p>
      )}
    </div>
  )
}
