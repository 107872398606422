import { WalletContextState } from '@solana/wallet-adapter-react'

const articleCreateAllowlist = [
  '9pT3uFkTXUqonHAiMyw9Y9Rvx1G3ShQnuGHzgwesiBb5',
  'C25TnkddeeiXJgBVasALztCgPSo2ibe1pXHU7pjGuJEg',
]

export const allowArticleCreate = (wallet: WalletContextState) => {
  return (
    process.env.SOLANA_RPC_CLUSTER_ENV === 'devnet' ||
    (wallet &&
      wallet.publicKey &&
      articleCreateAllowlist.includes(wallet?.publicKey.toBase58()))
  )
}
