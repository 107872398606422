import initSdk from '../utils/initSdk'

export const postNotificationsViewed = async () => {
  await initSdk()
  const sessionSignature = localStorage.getItem('sessionSignature')

  if (!sessionSignature) {
    throw new Error('Session signature not found')
  }

  const { message, signature, publicKey } = JSON.parse(sessionSignature)

  const notifications = await fetch(
    `${process.env.NINA_ID_ENDPOINT}/notifications/${publicKey}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message,
        signature,
        publicKey,
      }),
    },
  )

  return (await notifications.json()) as { success: boolean }
}
