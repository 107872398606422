'use client'

import { useEffect, useState } from 'react'
import {
  ButtonColor,
  ButtonFont,
  ButtonResponsiveness,
  ButtonVariant,
  ModalType,
} from '@/lib/types'
import * as pixel from '@/lib/utils/fpixel'
import Login from './Login'
import Modal from './Modal'

type LoginModalProps = {
  labelText?: string
  variant?: string
  backgroundOverride?: boolean
  loginModalLabelPromptString?: string
  redirectOnLogin?: string
  setMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>
  forceOpen?: boolean
  setForceOpen?: React.Dispatch<React.SetStateAction<boolean>>
  hideTrigger?: boolean
  labelTextColor?: ButtonColor
  labelColor?: ButtonColor
  onClick?: () => void
}

export default function LoginModal({
  labelText,
  variant = 'default',
  backgroundOverride,
  loginModalLabelPromptString,
  redirectOnLogin,
  setForceOpen,
  forceOpen,
  hideTrigger,
  setMenuOpen,
  labelTextColor = ButtonColor.Black,
  labelColor = ButtonColor.Black,
  onClick,
}: LoginModalProps) {
  const [isOpen, setIsOpen] = useState(forceOpen || false)
  useEffect(() => {
    if (forceOpen) {
      setIsOpen(true)
      pixel.event('login_modal_open')
    }
  }, [forceOpen])

  useEffect(() => {
    if (!isOpen && setForceOpen) {
      setForceOpen(false)
    }

    if (isOpen && onClick) {
      onClick()
    }
  }, [isOpen])

  const handleClose = () => {
    setIsOpen(false)

    if (setForceOpen) {
      setForceOpen(false)
    }

    if (setMenuOpen) {
      setMenuOpen(false)
    }
  }

  const responsiveness = loginModalLabelPromptString
    ? ButtonResponsiveness.MinW
    : ButtonResponsiveness.Full

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        label={labelText}
        modalHeader="Sign in or create account"
        modalLabelVariant={
          variant === 'navigation'
            ? ButtonVariant.Unstyled
            : ButtonVariant.Small
        }
        modalLabelResponsiveness={responsiveness}
        modalType={ModalType.Login}
        modalLabelFont={ButtonFont.Body1}
        modalLabelTextColor={labelTextColor}
        modalLabelColor={labelColor}
        disableKeydownSubmit={true}
        backgroundOverride={backgroundOverride}
        closeCallback={handleClose}
        hideTrigger={hideTrigger}
      >
        <Login
          setIsOpen={setIsOpen}
          loginModalLabelPromptString={loginModalLabelPromptString}
          redirectOnLogin={redirectOnLogin}
          setMenuOpen={setMenuOpen}
        />
      </Modal>
    </>
  )
}
