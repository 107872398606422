'use client'

import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

export default function ForceScroll() {
  const pathname = usePathname()
  const [popped, setPopped] = useState(false)
  useEffect(() => {
    window.addEventListener('popstate', function () {
      setPopped(true)
    })
  }, [])

  useEffect(() => {
    if (popped) {
      setPopped(false)
    } else {
      window.scroll(0, 0)
    }
  }, [pathname])

  return <></>
}
