import { FavoriteType, PublicKeyString } from '../types'
import initSdk from '../utils/initSdk'

export const addOrRemoveFavorite = async (
  contentPublicKey: PublicKeyString,
  favoriteType: FavoriteType,
) => {
  await initSdk()
  const sessionSignature = localStorage.getItem('sessionSignature')

  if (!sessionSignature) {
    throw new Error('Session signature not found')
  }

  const { message, signature, publicKey } = JSON.parse(sessionSignature)

  const body = {
    message,
    signature,
    publicKey,
    [favoriteType === FavoriteType.Release
      ? 'releasePublicKey'
      : 'postPublicKey']: contentPublicKey,
  } as {
    message: string
    signature: string
    publicKey: string
    releasePublicKey?: PublicKeyString
    postPublicKey?: PublicKeyString
  }

  const favoriteResponse = await fetch(
    `${process.env.NINA_ID_ENDPOINT}/favorites`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  )

  const data = (await favoriteResponse.json()) as {
    success: boolean
    action: 'added' | 'removed'
    favorite?: {
      publicKey: PublicKeyString
      accountPublicKey: PublicKeyString
      favoriteType: FavoriteType
      createdAt: string
      updatedAt: string
    }
  }

  return data
}
