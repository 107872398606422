import { Slider } from '@reach/slider'
import { useContext } from 'react'
import Audio from '@/contexts/AudioContext'
import { FavoriteType } from '@/lib/types'
import { formatDuration } from '@/lib/utils/formatDuration'
import FavoriteButton from './FavoriteButton'
import Icon from './tokens/Icon'

type AudioPlayerMobileControlsProps = {
  showMobileListeningTabs: boolean
  setShowMobileListeningTabs: (arg: boolean) => void
  publicKey?: string
}

export default function AudioPlayerMobileControls(
  props: AudioPlayerMobileControlsProps,
) {
  const { showMobileListeningTabs, setShowMobileListeningTabs, publicKey } =
    props

  const {
    activeQueueItem,
    duration,
    currentTime,
    isPlaying,
    playNextQueueItem,
    playPreviousQueueItem,
    pauseResume,
    handleSeek,
  } = useContext(Audio.Context)

  return (
    <div className="mobile_audio_controls bg-blue-82 px-0 sm:mt-20 sm:px-0 md:hidden">
      <div className="body-1 progress mb-20 hidden items-center text-white sm:block">
        <div className="mb-8 cursor-pointer ">
          <Slider
            value={currentTime}
            min={0}
            max={duration}
            onChange={handleSeek}
          />
        </div>

        <div className="flex w-full justify-between text-blue-35">
          <p className="ui-1-tabular">{formatDuration(currentTime)}</p>
          <p className="ui-1-tabular">{formatDuration(duration)}</p>
        </div>
      </div>

      <div className="m-0 flex justify-between py-12 sm:justify-center">
        {activeQueueItem() && (
          <div className="flex items-center sm:hidden">
            <FavoriteButton
              favoriteType={FavoriteType.Release}
              iconColor="white"
              publicKey={publicKey!}
              inAudioPlayer={true}
              title={activeQueueItem()!.release.metadata.name}
              source={'audioPlayer'}
            />
          </div>
        )}

        <div>
          <button onClick={() => playPreviousQueueItem()} className="p-8 ">
            <Icon name="previous" className="white" height={40} width={40} />
          </button>

          <button onClick={() => pauseResume()} className="mx-40">
            {isPlaying ? (
              <Icon
                name="pauseLarge"
                className="white"
                height={40}
                width={40}
              />
            ) : (
              <Icon name="playLarge" className="white" height={40} width={40} />
            )}
          </button>

          <button onClick={() => playNextQueueItem()} className="p-8 ">
            <Icon name="next" className="white" height={40} width={40} />
          </button>
        </div>

        <button
          className="sm:hidden"
          onClick={() => setShowMobileListeningTabs(!showMobileListeningTabs)}
        >
          {showMobileListeningTabs && (
            <Icon className="white" name="queueActive" />
          )}
          {!showMobileListeningTabs && <Icon className="white" name="queue" />}
        </button>
      </div>
    </div>
  )
}
