'use client'

import { useState } from 'react'
import { ListBoxView, QueueItem, Release, TrackListType } from '@/lib/types'
import ReleaseRepostModal from './ReleaseRepostModal'
import Ellipsis from './tokens/Ellipsis'

export default function QueueListItemListBox(props: {
  release: Release
  queueItem?: QueueItem
  view?: ListBoxView
  variant?: string
  index?: number
  isTrack?: boolean
  parentLength?: number
  type: TrackListType
}) {
  const { release, view, index, parentLength, queueItem, type } = props
  const [showRepost, setShowRepost] = useState(false)
  // const { moveQueueItem } = useContext(AudioContext.Context)

  const queueListOptions = {
    // moveToTop: {
    //   label: 'Move to top',
    //   type: 'moveToTop',
    //   callback: (queueItem: QueueItem, index: number) => {
    //     moveQueueItem(index, 1)
    //   },
    // },
    // moveUp: {
    //   label: 'Move up',
    //   type: 'moveUp',
    //   callback: (queueItem: QueueItem, index: number) => {
    //     moveQueueItem(index, index - 1)
    //   },
    // },
    // moveDown: {
    //   label: 'Move down',
    //   type: 'moveDown',
    //   callback: (queueItem: QueueItem, index: number) => {
    //     moveQueueItem(index, index + 1)
    //   },
    // },
    // moveToBottom: {
    //   label: 'Move to bottom',
    //   type: 'moveToBottom',
    //   callback: (queueItem: QueueItem, index: number) => {
    //     if (!parentLength) return

    //     moveQueueItem(index, parentLength - 1)
    //   },
    // },
    // divider: {
    //   label: '',
    //   type: 'divider',
    //   callback: () => null,
    // },
    repostRelase: {
      label: 'Repost',
      type: 'repostRelease',
      callback: () => {
        setShowRepost(true)
      },
    },
  }

  const historyListOptions = {
    repostRelase: {
      label: 'Repost',
      type: 'repostRelease',
      callback: () => {
        setShowRepost(true)
      },
    },
  }

  const options =
    type === TrackListType.Queue ? queueListOptions : historyListOptions

  return (
    <>
      <Ellipsis
        options={options}
        value={queueItem}
        view={view}
        variant={'queueItem'}
        parentIndex={index}
        lastParentIndex={parentLength ? parentLength - 1 : undefined}
        align={'left'}
      />
      {showRepost && (
        <ReleaseRepostModal
          release={release}
          forceOpen={true}
          hideTrigger={true}
        />
      )}
    </>
  )
}
