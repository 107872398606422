import Nina from '@nina-protocol/js-sdk'
import initSdk from '@/lib/utils/initSdk'
import { Account, PublicKeyString } from '../types'

export const fetchAccount = async (profilePublicKey: PublicKeyString) => {
  await initSdk()

  const accountResponse: Account = await Nina.Account.fetch(profilePublicKey, {
    v2: 'true',
  })

  return accountResponse
}
