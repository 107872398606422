'use client'

import ToastView from '@/components/ToastView'
import ToastContext from '@/contexts/ToastContext'

export default function Toasts({ children }: { children: React.ReactNode }) {
  return (
    <ToastContext.Provider>
      <ToastView />
      {children}
    </ToastContext.Provider>
  )
}
