'use client'

import { useContext } from 'react'
import Toast from '@/contexts/ToastContext'
import ToastItem from './tokens/ToastItem'

export default function ToastView() {
  const { toasts } = useContext(Toast.Context)

  return (
    <div className="fixed left-12 right-12 top-12 z-[1000] sm:left-auto md:right-20 md:top-20 lg:right-28 lg:top-28">
      {toasts?.map((toast) => (
        <ToastItem
          id={toast.id}
          key={toast.id}
          message={toast.message}
          variant={toast.variant}
          image={toast.image}
          link={toast.link}
          handleDeleteToast={toast.handleDeleteToast}
        />
      ))}
    </div>
  )
}
