export const formatDuration = (duration: number) => {
  const sec_num = Math.round(duration) || 0
  let hours: number | string = Math.floor(sec_num / 3600) || 0
  let minutes: number | string = Math.floor((sec_num - hours * 3600) / 60) || 0
  let seconds: number | string = sec_num - hours * 3600 - minutes * 60 || 0

  if (hours < 10) {
    hours = hours
  }

  if (minutes < 10) {
    minutes = minutes
  }

  if (hours > 0 && minutes < 10) {
    minutes = '0' + minutes
  }

  if (seconds < 10) {
    seconds = '0' + seconds
  }

  if (sec_num < 3600) {
    return minutes + ':' + seconds
  }

  return hours + ':' + minutes + ':' + seconds
}
