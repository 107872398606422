'use client'

import Image from 'next/image'
import { useRouter } from 'next/navigation'
import React, { useContext, useEffect, useState } from 'react'
import Toast from '@/contexts/ToastContext'
import Wallet from '@/contexts/WalletContext'
import { fetchAccount } from '@/lib/account/fetchAccount'
import {
  ButtonColor,
  ButtonFont,
  ButtonResponsiveness,
  ButtonVariant,
} from '@/lib/types'
import Button from './tokens/Button'
import Icon from './tokens/Icon'

export default function InitialSignInPopUp() {
  const router = useRouter()
  const { wallet, setInitialLoginFlag, profile } = useContext(Wallet.Context)

  const [profilePhoto, setProfilePhoto] = useState<string | undefined>(
    undefined,
  )

  const buttonLabel = 'Open profile'

  const { showProfileSettingsPopup, setShowProfileSettingsPopup } = useContext(
    Toast.Context,
  )

  useEffect(() => {
    const handleFetchAccount = async () => {
      if (!wallet?.publicKey) return

      const accountResponse = await fetchAccount(wallet.publicKey.toBase58())

      if (accountResponse) {
        setProfilePhoto(accountResponse.image)

        if (accountResponse.image === '') {
          setShowProfileSettingsPopup(true)
        }
      }
    }

    handleFetchAccount().catch(console.error)
  }, [wallet?.publicKey])

  // comment out this useEffect to test the popup making it visible everytime
  useEffect(() => {
    if (profilePhoto === '') {
      setShowProfileSettingsPopup(true)
    } else {
      if (setInitialLoginFlag) {
        setInitialLoginFlag(false)
      }

      setShowProfileSettingsPopup(false)
    }
  }, [profilePhoto])

  const handleInitialLoginFlow = () => {
    router.push(`/profiles/${profile!.handle}`)

    if (setInitialLoginFlag) {
      setInitialLoginFlag(true)
    }

    setShowProfileSettingsPopup(false)
  }

  const handleClose = () => {
    setShowProfileSettingsPopup(false)

    if (setInitialLoginFlag) {
      setInitialLoginFlag(false)
    }
  }

  return (
    <>
      {showProfileSettingsPopup && (
        <div className="fixed bottom-[64px] left-12 z-[9] h-auto w-[calc(100vw_-_24px)] border-1 border-grey-15 bg-white p-12 md:w-[376px] lg:bottom-[136px] lg:left-28">
          <div className="relative">
            <div className="flex flex-col">
              <div className="relative flex flex-row justify-between">
                <div>
                  <Image
                    src={'/assets/little-guy.png'}
                    alt="little guy"
                    className="h-[96px] w-[83px]"
                    width={186}
                    height={192}
                  />
                </div>
                <button
                  className="absolute right-0"
                  onClick={() => handleClose()}
                >
                  <span>
                    <Icon
                      name="closeLarge"
                      height={16}
                      width={16}
                      className="black h-16 w-16 md:hover:opacity-70"
                    />
                  </span>
                </button>
              </div>
              <p className="body-1 my-12">
                Welcome to Nina. Set up your profile to get started!
              </p>
              <div className="w-32">
                <Button
                  color={ButtonColor.Blue}
                  label={buttonLabel}
                  variant={ButtonVariant.Small}
                  font={ButtonFont.Body1}
                  textColor={ButtonColor.Blue}
                  responsiveness={ButtonResponsiveness.MinW}
                  onClick={() => handleInitialLoginFlow()}
                />
              </div>
            </div>
          </div>
          <p
            className="caption-1 absolute bottom-[-40px] left-0 cursor-pointer hover:opacity-80"
            onClick={() => handleClose()}
          >
            Close
          </p>
        </div>
      )}
    </>
  )
}
