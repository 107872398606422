import { Favorite, PaginationProps, PublicKeyString } from '../types'
import initSdk from '../utils/initSdk'

export const fetchFavoritesForRelease = async (
  releasePublicKey: PublicKeyString,
  pagination?: PaginationProps,
) => {
  await initSdk()

  const favoritesResponse = await fetch(
    `${
      process.env.NINA_ID_ENDPOINT
    }/favorites/release?publicKey=${releasePublicKey}&limit=${
      pagination?.limit || 25
    }&offset=${pagination?.offset || 0}`,
  )

  const data = (await favoritesResponse.json()) as {
    success: boolean
    favorites: Favorite[]
    total: number
  }

  return data
}
