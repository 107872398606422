import clsx from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { useContext, useEffect, useMemo } from 'react'
import FileService from '@/contexts/FileServiceContext'
import {
  ButtonColor,
  ButtonResponsiveness,
  ButtonVariant,
  Toast,
} from '@/lib/types'
import ImagePlaceholderBase64 from '@/lib/utils/ImagePlaceholderBase64'
import { detectMobile } from '@/lib/utils/useScreenWidth'
import Button from './Button'

export default function ToastItem({
  message,
  variant,
  id,
  image,
  link,
  handleDeleteToast,
}: Toast) {
  const router = useRouter()
  const isMobile = detectMobile()
  const { pendingUpload, clearPendingUpload } = useContext(FileService.Context)

  const ctaResponsiveness = isMobile
    ? ButtonResponsiveness.Full
    : ButtonResponsiveness.MinW

  useEffect(() => {
    if (!pendingUpload && variant === 'uploadComplete') {
      if (handleDeleteToast) {
        handleDeleteToast(id)
      }
    }
  }, [pendingUpload])

  const toastMessage = useMemo(() => {
    if (variant === 'uploadComplete' && pendingUpload) {
      const noun = pendingUpload?.type === 'post' ? 'post' : 'release'

      if (pendingUpload.status === 'done') {
        return `Your ${noun} is now live`
      } else if (pendingUpload.status === 'scheduled') {
        return `Your ${noun} is scheduled`
      }
    }

    return message
  }, [pendingUpload])

  return (
    <div
      id={`${id}`}
      className={clsx(
        'toast body-1 z-auto mb-20 flex min-h-[40px] w-full scroll-pl-16 items-center border-[1.25px] border-solid py-10 pl-16 sm:w-[452px]',
        variant === 'success' &&
          'toast-success  border-green-dark text-green-dark',
        variant === 'error' && 'toast-error  border-red-dark text-red-dark',
        variant === 'loading' && 'toast-loading  border-blue-82 text-blue-82',
        variant === 'uploadComplete' &&
          'h-auto  flex-col border-1 border-blue-82 bg-white !p-12 text-blue-82 sm:h-[64px] sm:flex-row sm:justify-between',
      )}
    >
      <div className="flex w-full flex-row items-center gap-x-10 sm:pr-10">
        {image && (
          <div className="flex">
            <Image
              alt="processing"
              src={image}
              width={40}
              height={40}
              placeholder="blur"
              blurDataURL={ImagePlaceholderBase64}
              className="imageBorder flex h-[40px] min-w-[40px] "
            />
          </div>
        )}
        <p className="pr-10">{toastMessage}</p>
      </div>
      {variant === 'uploadComplete' && (
        <div className="mt-12 flex w-full flex-row gap-x-8 sm:mt-0 sm:w-auto ">
          <Button
            variant={ButtonVariant.Small}
            label="View"
            color={ButtonColor.Solid}
            responsiveness={ctaResponsiveness}
            onClick={() => {
              if (pendingUpload && pendingUpload.status === 'done') {
                if (pendingUpload.type === 'post') {
                  router.push(`/articles/${pendingUpload.slug}`)
                } else if (pendingUpload.type === 'release') {
                  router.push(
                    `/releases/${
                      pendingUpload.slug || pendingUpload.publicKey
                    }`,
                  )
                }
              } else if (link) {
                router.push(link)
              }

              clearPendingUpload(true)

              if (handleDeleteToast) {
                handleDeleteToast(id)
              }
            }}
          />
          <Button
            variant={ButtonVariant.Small}
            label="Dismiss"
            color={ButtonColor.Blue}
            responsiveness={ctaResponsiveness}
            onClick={() => {
              if (handleDeleteToast) {
                handleDeleteToast(id)
              }
            }}
          />
        </div>
      )}
    </div>
  )
}
