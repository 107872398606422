'use client'

import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import Toast from '@/contexts/ToastContext'
import Update from '@/contexts/UpdateContext'
import Wallet from '@/contexts/WalletContext'
import { addOrRemoveFavorite } from '@/lib/favorite/addOrRemoveFavorite'
import { fetchFavoritesForRelease } from '@/lib/favorite/fetchFavoritesForRelease'
import {
  FavoriteButtonSource,
  FavoriteButtonVariant,
  FavoriteType,
  PublicKeyString,
} from '@/lib/types'
import { logEvent } from '@/lib/utils/event'
import LoginModal from './LoginModal'
import Icon from './tokens/Icon'

export default function FavoriteButton({
  publicKey,
  favoriteType,
  favoriteButtonVariant,
  iconColor,
  styleOverride,
  view,
  title,
  source,
}: {
  publicKey: PublicKeyString
  favoriteType: FavoriteType
  iconColor?: string
  rowHovered?: boolean
  favoriteButtonVariant?: FavoriteButtonVariant
  styleOverride?: string
  inAudioPlayer?: boolean
  view?: string
  title?: string
  source: FavoriteButtonSource
}) {
  const { favorites, fetchFavorites, userHasFavoritedItem, wallet } =
    useContext(Wallet.Context)

  const { addToast } = useContext(Toast.Context)
  const { date, update } = useContext(Update.Context)
  const [isFavorite, setIsFavorite] = useState(false)
  const [pending, setPending] = useState(false)
  const [forceOpen, setForceOpen] = useState(false)

  const [favoriteCount, setFavoriteCount] = useState<number | undefined>(
    undefined,
  )

  useEffect(() => {
    if (wallet.publicKey && publicKey && favorites) {
      setIsFavorite(userHasFavoritedItem(publicKey))
    }
  }, [wallet.publicKey, favorites, publicKey])

  useEffect(() => {
    if (!view) return

    const handleFetchFavorites = async () => {
      try {
        if (!publicKey) return

        if (publicKey) {
          const { favorites } = await fetchFavoritesForRelease(publicKey)

          if (favorites) {
            setFavoriteCount(favorites.length)
          }
        }
      } catch (error) {
        console.error('Failed to fetch favorites', error)
      }
    }

    handleFetchFavorites()
  }, [publicKey, date])

  const handleFavoriteButtonSelected = async () => {
    // setPending(true)
    try {
      setPending(true)

      if (!wallet.publicKey) {
        setForceOpen(true) // Prompt user to log in
        setPending(false)
        handleFavoriteEvent(source, isFavorite)

        return
      }

      const wasFavorite = isFavorite
      setIsFavorite(!isFavorite)
      setFavoriteCount((prevCount) => {
        if (prevCount === undefined) {
          prevCount = 0
        }

        return wasFavorite ? prevCount - 1 : prevCount + 1
      })

      addToast({
        message: wasFavorite
          ? `${title} removed from favorites`
          : `${title} added to favorites`,
        variant: 'success',
      })

      const { success } = await addOrRemoveFavorite(publicKey, favoriteType)

      if (!success) {
        throw new Error('Failed to add or remove favorite')
      }

      if (success) {
        handleFavoriteEvent(source, isFavorite)
        fetchFavorites()
        update()
      }

      setPending(false)
    } catch (error) {
      addToast({
        message: 'Failed to add to favorites',
        variant: 'error',
      })
      setPending(false)
    }
  }

  const handleFavoriteEvent = (source: string, isFavorite: boolean) => {
    if (!wallet.publicKey) {
      logEvent('favorite_release_not_logged_in', 'interaction', wallet, {
        source,
        favoriteType,
        publicKey,
      })

      return
    }

    const action = `favorite_release_${isFavorite ? 'remove' : 'add'}`

    const params = {
      source,
      favoriteType,
      publicKey,
    }

    logEvent(action, 'interaction', wallet, params)
  }

  return (
    <div
      className={clsx(
        'flex flex-row sm:flex-col',
        view === 'post' && 'sm:!flex-row',
      )}
    >
      <button
        onClick={() => handleFavoriteButtonSelected()}
        disabled={pending}
        className={clsx(
          'h-[20px] w-[20px]',
          favoriteButtonVariant === FavoriteButtonVariant?.ReleaseList &&
            !isFavorite &&
            'opacity-0 group-hover:opacity-100',
          styleOverride,
        )}
      >
        <LoginModal
          forceOpen={forceOpen}
          setForceOpen={setForceOpen}
          hideTrigger={true}
          loginModalLabelPromptString={
            'Create an account or sign in to favorite this release.'
          }
        />
        {isFavorite && (
          <Icon
            className={clsx(
              iconColor || 'grey-35',
              isFavorite && 'hover:opacity-80',
            )}
            name={'starFilled'}
            height={20}
            width={20}
          />
        )}
        {!isFavorite && (
          <Icon
            className={clsx(iconColor || 'grey-35', 'hover:opacity-80')}
            name={'starOutlined'}
            height={20}
            width={20}
          />
        )}
      </button>
      {(view === 'release' || view === 'post') && (
        <p
          className={clsx(
            'ui-1 flex items-center justify-center text-grey-35 xs:ml-[6px] sm:ml-[0px]',
            view === 'post' && 'w-20',
          )}
        >
          {favoriteCount}
        </p>
      )}
    </div>
  )
}
