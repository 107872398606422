import clsx from 'clsx'
import { QueueItem } from '@/lib/types'

// import { detectMedDown } from '@/lib/utils/useScreenWidth'
// import Marquee from './tokens/Marquee'

type TrackTitleDisplayProps = {
  queueItem: QueueItem
  type: 'drawer' | 'player'
}

export default function TrackTitleDisplay(props: TrackTitleDisplayProps) {
  const { queueItem, type } = props
  const { release, trackNumber } = queueItem
  // const isMedDown = detectMedDown()
  const isMedDown = false

  if (!release) return null

  const { name } = release.metadata
  const displayLength = name.length
  const displayLimit = isMedDown ? 35 : 75
  const isAlbum = release.metadata.properties.files.length > 1

  if (displayLength > displayLimit && isMedDown) {
    return (
      <div className=" max-w-[85vw] overflow-x-hidden">
        {/* <Marquee type={type}>
          <p
            className={clsx(
              'pr-12',
              type === 'player' && 'max-w-[100%] whitespace-nowrap',
              type === 'drawer' &&
                'blue-title md:max- w-fit max-w-[100%]  overflow-x-hidden  whitespace-nowrap sm:block sm:whitespace-nowrap  lg:max-w-none',
            )}
          >
            {' '}
            <span>{name}</span>
            <h1>MARQUEE</h1>
            {isAlbum && (
              <>
                <span
                  className={clsx(
                    'ml-2',
                    type === 'player' ? 'text-grey-25' : 'text-blue-35',
                  )}
                >
                  /{' '}
                  {
                    release.metadata.properties.files.find(
                      (file) => file.track === trackNumber,
                    )?.track_title
                  }
                </span>
              </>
            )}
          </p>
        </Marquee> */}
      </div>
    )
  } else {
    return (
      <p
        className={clsx(
          'pr-12',
          type === 'player' && 'max-w-full !truncate  ',
          type === 'drawer' &&
            ' max-w-[calc(100vw_-_24px)]  overflow-x-hidden  xs:truncate sm:block sm:max-w-none sm:whitespace-normal  lg:max-w-none',
        )}
      >
        {' '}
        {isAlbum && (
          <>
            {' '}
            <span
              className={clsx(
                type === 'player' && 'text-grey-45',
                type === 'drawer' && 'text-blue-35',
              )}
            >
              {' '}
              {
                release.metadata.properties.files.find(
                  (file) => file.track === trackNumber,
                )?.track_title
              }{' '}
              <span className={clsx(type === 'drawer' && 'text-white')}>/</span>{' '}
            </span>{' '}
          </>
        )}
        <span>{name}</span>
      </p>
    )
  }
}
