type Props = {
  className: string
  height?: number
  width?: number
}

export default function Logo({ className, height, width }: Props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3628 940"
      className={`fill-${className} h-[20px]`}
    >
      <path
        d="M2668,470c0-265.1,214.9-470,480-470,135.8,0,248.3,55,330,157.7V30h150V910H3478V782.3C3396.3,884.9,3283.8,940,3148,940,2882.9,940,2668,735.1,2668,470Zm480-330c182.2,0,330,147.7,330,330s-147.8,330-330,330-330-147.7-330-330S2965.8,140,3148,140Zm-1138,0c182.2,0,330,147.7,330,330V910h150V470c0-265.1-214.9-470-480-470-135.8,0-248.3,55-330,157.7V30H1530V910h150V470C1680,287.7,1827.8,140,2010,140ZM1315,30H1165V910h150ZM810,470c0-182.3-147.7-330-330-330S150,287.7,150,470V910H0V30H150V157.7C231.7,55,344.2,0,480,0,745.1,0,960,204.9,960,470V910H810Z"
        fillRule="evenodd"
        fill={className}
      />
    </svg>
  )
}
